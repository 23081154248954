import React from 'react';
import { V3ITEMS } from './constants';

export const ContextV3 = React.createContext(null);
const initCount = V3ITEMS.reduce(
  (count, item) => Object.assign(count, { [`${item.type}-${item.key}`]: 0 }),
  {}
);

class AppProvider extends React.Component {
  state = {
    cameraMode: 'top',
    itemCount: initCount,
  };

  setItemCount = (newCount) => {
    this.setState({
      itemCount: newCount,
    });
  };

  updateItemCount = (newCount) => {
    this.setState({ itemCount: { ...this.state.itemCount, ...newCount } });
  };

  getItemCount = (type, key) => {
    return this.state.itemCount[`${type}-${key}`] || 0;
  };

  setCameraMode = (mode) => {
    this.setState({ cameraMode: mode });
  };

  getCameraMode = () => this.state.cameraMode;

  componentDidMount() {
    let isMobile = false;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      isMobile = true;
    }
    const params = {
      el: 'player-root',
      device: isMobile ? 'mobile' : 'desktop',
      // mode: 'vray',
      // productCategory: 'sac',
      // initialConfiguration: {
      //   // productId: 'cdc8144c-2654-4a6b-b898-81f5ca6f8cf7', // 3x5
      //   productId: '886941cc-7890-42e4-aa94-27aab97f0c4d', // bigone sac
      //   fabric: '318',
      //   floor: 'Espresso',
      //   // ArmType: 'Rollarm',
      //   // StealthTech: 'core',
      // },
    };
    const url = new URL(window.location.href);

    for (const [key, val] of url.searchParams.entries()) {
      if (/(mode|productCategory)/i.test(key)) {
        params[key] = val;
      } else {
        switch (key) {
          case 'productId':
          case 'fabric':
          case 'floor':
          case 'ArmType':
            if (!params.initialConfiguration) {
              params.initialConfiguration = { [key]: val };
            } else params.initialConfiguration[key] = val;
            break;
          default:
            break;
        }
      }
    }
    window.threekit.init(params).then(() => {
      window.api = window.threekit.api;
      window.configurator = window.threekit.configurator;
    });
  }

  render() {
    return (
      <ContextV3.Provider
        value={{
          ...this.state,
          setItemCount: this.setItemCount,
          getItemCount: this.getItemCount,
          updateItemCount: this.updateItemCount,
        }}
      >
        {this.props.children}
      </ContextV3.Provider>
    );
  }
}

export default AppProvider;
