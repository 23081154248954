import React, { useState } from 'react';

import Presets from './Presets';
import { Wrapper } from '../Configurator/configurator.styles';
import { StepWrapper } from './configuratorv3.style';
import { ButtonWrapper } from '../Player/player.styles';
import { Button } from 'antd';
import SactionalItems from './SactionalItems';
import GlobalConfig from './GlobalConfig';

const STEPS = [Presets, SactionalItems, GlobalConfig];

const ConfiguratorV3 = ({ items, onClick }) => {
  const [stepIdx, setStepIdx] = useState(0);

  return (
    <Wrapper>
      <h2>Room Configurator</h2>
      {STEPS.map((Child, idx) => (
        <StepWrapper key={idx} open={idx === stepIdx}>
          <Child />
        </StepWrapper>
      ))}
      <ButtonWrapper>
        <Button
          disabled={stepIdx === 0}
          onClick={() => setStepIdx(stepIdx - 1)}
        >
          Previous
        </Button>
        <Button
          disabled={stepIdx === STEPS.length - 1}
          onClick={() => setStepIdx(stepIdx + 1)}
        >
          Next
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ConfiguratorV3;
