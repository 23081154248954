import styled from 'styled-components';

export const ItemWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  cursor: pointer;
`;

export const Item = styled.div`
  padding: 10px;

  width: 200px;
  height: 150px;
  position: relative;

  border: ${(props) => (props.selected ? '1px solid' : '1px transparent')};

  img {
    height: calc(100% - 20px);
  }
`;

export const Count = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: gray;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: white;
`;
