import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  position: relative;

  #player-root {
    width: calc(100vw - 380px);
    height: 100vh;
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  width: 300px;
  display: flex;
  justify-content: space-between;
  left: calc(50% - 150px);
`;

export const ARButtonWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: space-between;
  left: 8px;
`;

// BlueAcorn's desktop player resolution
// export const Wrapper = styled.div`
//   height: 464px;
//   width: 880px;

//   #player-root {
//     height: 464px;
//     width: 880px;
//   }
// `;
