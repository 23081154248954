import standardSeatSet from './img/Standard Seat Set.png';
import disconnected6seats6sides from './img/6 Seats 6 Sides (disconnected).png';
import disconnectedRollarm6seats6sides from './img/6 Seats 6 Sides Rollarm (disconnected).png';
import connected4seats5sides from './img/4 Seats 5 Sides (connected).png';
import connectedRollarm4seats5sides from './img/4 Seats 5 Sides Rollarm (connected).png';
import connectedOutdoor4seats5sides from './img/4 Seats 5 Sides Outdoor (connected).png';
import doubleSidedSide6seats7sides from './img/6 Seats 7 Sides doubled sided sides.png';

export const SECTIONS = {
  addItem: 'addItem',
  configureItem: 'configureItem',
  floorConfigrator: 'floorConfigurator',
};

export const V3ITEMS = [
  {
    label: 'Standard Seat',
    type: 'seat',
    key: 'standard',
    collection: 'indoor',
    src: '',
  },
  {
    label: 'Wedge Seat',
    type: 'seat',
    key: 'wedge',
    collection: 'indoor',
    src: '',
  },
  {
    label: 'Anytable',
    type: 'anytable',
    key: 'standard',
    collection: 'indoor',
    src: '',
  },
  {
    label: 'Anytable 2-Shelves',
    type: 'anytable',
    key: '2shelves',
    collection: 'indoor',
    src: '',
  },
  {
    label: 'Anytable - Shelf Drawer',
    type: 'anytable',
    key: 'shelfDrawer',
    collection: 'indoor',
    src: '',
  },
  {
    label: 'Standard Side',
    type: 'side',
    key: 'standard',
    collection: 'indoor',
    src: '',
  },
  {
    label: 'Deep Side',
    type: 'side',
    key: 'deep',
    collection: 'indoor',
    src: '',
  },
  {
    label: 'Angled Side',
    type: 'side',
    key: 'angled',
    collection: 'indoor',
    src: '',
  },
  {
    label: 'Deep Angled Side',
    type: 'side',
    key: 'deepAngled',
    collection: 'indoor',
    src: '',
  },
  {
    label: 'Double Sided Side',
    type: 'side',
    key: 'doubleSided',
    collection: 'indoor',
    src: '',
  },
  {
    label: 'Roll Arm Side',
    type: 'side',
    key: 'rollArm',
    collection: 'indoor',
    src: '',
  },
];

export const V3PRESET = [
  {
    label: 'Standard Seat Set',
    id: '93f051b1-64ce-4906-bcd5-d27ecda1ba1d',
    src: standardSeatSet,
  },
  {
    label: '6 Seats 6 Sides (disconnected)',
    id: '4619293a-d681-4664-b344-6f20d21bdcf1',
    src: disconnected6seats6sides,
  },
  {
    label: '6 Seats 6 Sides rollarm (disconnected)',
    id: 'ea585574-f9d6-4c14-bc0a-c8f1babe1863',
    src: disconnectedRollarm6seats6sides,
  },
  {
    label: '4 Seats 5 Sides (connected)',
    id: '2fede009-3cfe-4659-a7bb-6f390bd8ff04',
    src: connected4seats5sides,
  },
  {
    label: '4 Seats 5 Sides rollarm (connected)',
    id: '84f415ce-500f-4dca-a6a1-a89be6ffd800',
    src: connectedRollarm4seats5sides,
  },
  {
    label: '6 Seats 7 Sides doubled sided sides',
    id: 'c0acdc6e-1f27-4459-a87f-203f944dac9b',
    src: doubleSidedSide6seats7sides,
  },
];

export const FLOORS = [
  'WhiteOak',
  'CoastalGrey',
  'SaddleBrown',
  'Chestnut',
  'Espresso',
];

export const FABRICS = ['317', '401', '625', '1422', '442', '627', '2948'];
