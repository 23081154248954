import React, { useContext } from 'react';
import { ContextV3 } from '../../../contextV3';

import Items from '../Items';
import { V3PRESET } from '../../../constants';

const Presets = () => {
  const { setItemCount } = useContext(ContextV3);
  const onPresetClick = async (preset) => {
    await window.threekit.configurator.setProduct(preset.id);
    const items = window.threekit.configurator.getItems();
    const itemCount = Object.values(items).reduce((count, item) => {
      const key = `${item._type}-${item._key}`;

      count[key] = (count[key] || 0) + 1;
      return count;
    }, {});

    setItemCount(itemCount);
  };
  return <Items items={V3PRESET} onClick={onPresetClick} />;
};

export default Presets;
