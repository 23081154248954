import React from 'react';

import { Select, Switch } from 'antd';
import { FLOORS, FABRICS } from '../../../constants';

const GlobalConfig = () => {
  const handleSetFloor = (val) => window.threekit.configurator.setFloor(val);
  const handleSetFabroc = (val) => window.threekit.configurator.setFabric(val);
  const onCameraViewChange = (checked) =>
    window.threekit.configurator.setCameraView(checked ? 'hero' : 'top');
  const onDimensionChange = (checked) =>
    window.threekit.configurator.toggleMeasurement(checked ? 'on' : 'off');
  const onStealthTechChange = (val) =>
    window.threekit.configurator.setStealthTech({
      type: 'stealthTech',
      key:
        val === 'None'
          ? undefined
          : val === 'Core'
          ? `6SpeakerSactionalStealthBundle`
          : `7SpeakerSactionalStealthBundle`,
      showIndicators: val != 'None',
    });

  const onSetWood = (val) => window.threekit.configurator.setWood(val);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      Floor:
      <Select
        onChange={handleSetFloor}
        placeholder="Select a floor type"
        style={{ width: '160px' }}
      >
        {FLOORS.map((type, i) => (
          <Select.Option key={i} value={type}>
            {type}
          </Select.Option>
        ))}
      </Select>
      Fabric:
      <Select
        onChange={handleSetFabroc}
        placeholder="Select Fabric Code"
        style={{ width: '160px' }}
      >
        {FABRICS.map((type, i) => (
          <Select.Option key={i} value={type}>
            {type}
          </Select.Option>
        ))}
      </Select>
      StealthTech:
      <Select
        onChange={onStealthTechChange}
        placeholder="Select StealthTech Speaker Number"
        style={{ width: '160px' }}
      >
        {['None', 'Core', 'Optimal'].map((num, i) => (
          <Select.Option key={i} value={num}>
            {num}
          </Select.Option>
        ))}
      </Select>
      Wood:
      <Select
        onChange={onSetWood}
        placeholder="Select Wood Type"
        style={{ width: '160px' }}
      >
        {[
          'Blonde Oak Veneer',
          'Weathered Gray Ash Veneer',
          'Dark Brown Oak Veneer',
        ].map((num, i) => (
          <Select.Option key={i} value={num}>
            {num}
          </Select.Option>
        ))}
      </Select>
      <Switch
        checkedChildren="Hero View"
        unCheckedChildren="Top View"
        onChange={onCameraViewChange}
      />
      <Switch
        checkedChildren="Dimension On"
        unCheckedChildren="Dimension Off"
        onChange={onDimensionChange}
      />
    </div>
  );
};

export default GlobalConfig;
