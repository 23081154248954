import React, { useState } from 'react';
import { ItemWrapper, Item, Count } from './items.style';

const Items = ({ items, onClick, getItemCount }) => {
  const [selectedItemIdx, setSelectItemIdx] = useState(null);
  const onItemClick = (item, idx) => {
    setSelectItemIdx(idx);
    onClick(item);
  };
  return (
    <ItemWrapper>
      {items.map((item, idx) => (
        <Item
          selected={selectedItemIdx === idx}
          onClick={() => onItemClick(item, idx)}
          key={idx}
        >
          <img src={item.src} alt={'Image Not Found'} />
          <p>{item.label}</p>
          {getItemCount !== undefined && <Count>{getItemCount(item)}</Count>}
        </Item>
      ))}
    </ItemWrapper>
  );
};

export default Items;
