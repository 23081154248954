import React, { useContext, useEffect, useState } from 'react';
import { V3ITEMS } from '../../../constants';
import { ContextV3 } from '../../../contextV3';
import Items from '../Items';

const SactionalItems = () => {
  const { getItemCount, updateItemCount, setItemCount } = useContext(ContextV3);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSactionalItemClick = (item) => {
    const { displayPlusSign } = window.threekit.configurator;

    setSelectedItem(item);
    displayPlusSign(item.type, item.key);
  };

  const handleAddItem = (e) => {
    const { displayPlusSign } = window.threekit.configurator;
    const { type, key } = selectedItem;

    displayPlusSign(type, key);
    updateItemCount({ [`${type}-${key}`]: getItemCount(type, key) + 1 });
  };

  const handleItemCountChange = (e) => {
    const { getItems } = window.threekit.configurator;
    const newCount = Object.values(getItems()).reduce((count, item) => {
      const key = `${item._type}-${item._key}`;
      count[key] = (count[key] || 0) + 1;

      return count;
    }, {});

    setItemCount(newCount);
  };

  const handleSelectChange = (e) => {
    const selectedId = e.detail.id;
    const { displayPlusSign } = window.threekit.configurator;

    if (selectedId === null && selectedItem) {
      displayPlusSign(selectedItem.type, selectedItem.key);
    }
  };

  useEffect(() => {
    window.addEventListener('itemAdd', handleAddItem);
    window.addEventListener('itemDelete', handleItemCountChange);
    window.addEventListener('itemSelect', handleSelectChange);
    window.addEventListener('convertSide', handleItemCountChange);
    return () => {
      window.removeEventListener('itemAdd', handleAddItem);
      window.removeEventListener('itemDelete', handleItemCountChange);
      window.removeEventListener('itemSelect', handleSelectChange);
      window.removeEventListener('convertSide', handleItemCountChange);
    };
  });
  const handleItemCountCalculate = (item) => {
    const count = getItemCount(item.type, item.key);
    return count;
  };

  return (
    <Items
      items={V3ITEMS}
      onClick={handleSactionalItemClick}
      getItemCount={handleItemCountCalculate}
    />
  );
};

export default SactionalItems;
