import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 380px;

  padding: 15px 20px;
  border-left: 1px solid #bbbbbb;

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  position: relative;
`;
